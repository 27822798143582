import React, { useEffect, useState } from 'react';
import { request_demo_arrow_icon, ProschoolLogo, call_image, book_demon_request_received_image } from "../../assets/images/images";
import { FormControl, TextField, FormHelperText } from "@mui/material";
import "./bookDemo.css";
import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from 'react-redux';
import GetCountryCodeViaIp from "../../utils/GetCountryCode.js";
import { allCountries } from "country-telephone-data";
import { useNavigate } from 'react-router-dom';
import { Countries as countries } from "../../components/CountryDropDown/Countries.js";
import { GOOGLE_MAP_KEY } from "../../consts/constants";
import { Autocomplete as CountryCodeAutoComplete } from "@mui/material";
import {
    isRequired,
    isValidEmail,
    isValidPassword,
} from "../../utils/validator";
import { bookADemo, resetBookADemo } from "../../state/actions/bookADemoAction"
import { useAuth } from '../../features/auth/AuthContext.js';
import { toast } from "react-toastify";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { el } from 'date-fns/locale';
import { Helmet } from 'react-helmet';

const BookDemo = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useAuth();
    const bookdemo = useSelector((state) => state.bookdemo);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [hasSelectedPlace, setHasSelectedPlace] = useState(false);
    const [getCountryCode, setgetCountryCode] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(countries);
    const [formErrors, setFormErrors] = useState({
        full_name: "",
        email: "",
        country_code: "",
        phone: "",
        school_name: "",
        referred_by: "",
        answer: "",
    });
    const [formState, setFormState] = useState({
        full_name: "",
        email: "",
        country_code: "",
        phone: "",
        school_name: "",
        referred_by: "",
        answer: "",
    });
    const [showTextarea, setShowTextarea] = useState(false);
    const [successMessage, setsuccessMessage] = useState(false);


    const handlePlaceSelect = (place) => {
        if (place && place.formatted_address) {
            setFormState((prev) => ({
                ...prev,
                school_name: place.formatted_address,
            }));
            setFormErrors({ school_name: "" });
            setHasSelectedPlace(true);
        } else {
            setFormErrors({ school_name: "Please enter a valid location" });
        }
    };

    const handleAutocompleteInputChange = (event) => {
        setFormState((prev) => ({
            ...prev,
            school_name: event.target.value,
        }));
        if (event.target.value === "" || !hasSelectedPlace) {
            setFormErrors({ school_name: "Please enter a valid location" });
        } else {
            setFormErrors({ school_name: "" });
        }
    };

    const handleInputBlur = () => {
        if (!hasSelectedPlace && formState.school_name) {
            setFormState((prev) => ({
                ...prev,
                school_name: "",
            }));
            setFormErrors({ school_name: "Please enter a valid location" });
        }
    };

    const handleCountryChange = (e) => {
        const { name, value } = e.target;
        setFormState({
            ...formState,
            [name]: value,
        });
    }

    const handleChange = (e) => {

        if (e.key === "Enter") {
            e.preventDefault(); // Prevent the default behavior (e.g., form submission)
            handleSubmit(e); // Call the submit function
            return;
        }

        const { name, value } = e.target;
        let error = "";
        if (name === "full_name") {
            error = isRequired(value, "full_name");
        } else if (name === "email") {
            error = isRequired(value, "email") || isValidEmail(value);
        } else if (name === "referred_by") {
            if (value === "4") {
                setShowTextarea(true);
            } else {
                setShowTextarea(false);
            }
        }

        setFormErrors({
            ...formErrors,
            [name]: error,
        });

        setFormState({
            ...formState,
            [name]: value,
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let errors = {};

        errors.email =
            isRequired(formState.email, "email") || isValidEmail(formState.email);
        errors.full_name =
            isRequired(formState.full_name, "full name")

        setFormErrors(errors);

        if (!Object.values(errors).some((error) => error)) {
            await handleRequestDemo();
        }
    };

    const handleRequestDemo = () => {
        const cleanObject = (obj) => Object.entries(obj).reduce((acc, [key, value]) => {
            if (value !== null && value !== "") {
                acc[key] = value;
            }
            return acc;
        }, {});

        const cleanedFormData = cleanObject(formState);
        dispatch(bookADemo(cleanedFormData, token))
    }



    const defaultProps = {
        options: countries,
        getOptionLabel: (option) => option.dial_code// Return only the dial_code
    };

    // const filterOptions = () => {
    //     const demo = countries.filter((option) => option.dial_code === formState.country_code)
    //     console.log(demo);
    //     return demo;
    // };

    // const filterOptions = (inputValue) => {
    //     // console.warn(formState.country_code, inputValue);
    //     // console.warn('hello');
    //     // if (!formState.country_code) return countries; // If no input, return all countries
    //     return formState.country_code?  countries.filter(option => option.dial_code.includes(formState.country_code)) : countries
    // };


    // const filterOptions = formState.country_code ? countries.filter(option => option.dial_code === formState.country_code).dial_code : countries.map((country) => country.dial_code)
    const filterOptions = countries.filter(option => option.dial_code.includes(formState.country_code))



    useEffect(() => {
        if (bookdemo.message) {
            setsuccessMessage(true);
            dispatch(resetBookADemo());
        }
        else if (bookdemo.error) {
            toast.error(bookdemo.error);
            dispatch(resetBookADemo());
        }
    }, [bookdemo])

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (successMessage) {
            setTimeout(() => {
                setsuccessMessage(false);
                // window.location.href = 'https://crm.zoho.in/bookings/ProSchoolDemo?rid=6178c3ebae0db6dde263550288f2e43dcb116db82b90fe56443a8e99d785770849f0b633869eb9b32b0d21a8650121b5gid8448aaaed34574ac158ffd90cf68d04d4dbf42fd89ec0c98e2bfd39b89e7e432'
                // window.location.href = 'https://calendly.com/info-lq_y/proschool-demo?month=2024-04'
                window.location.href = 'https://crm.zoho.in/bookings/ProSchoolDemoLink?rid=9424d97aec6e11d1c3ae7d9c6646b7c7c3e9aa55254364fc19a39b4e58b119faf0d28843c0b135f8f3fd6c3c4b0b201cgid9bc6a2232969d709e2e525070d49ce0aef91c1940bc27de028796ab325e88d0c'
            }, 2000)
        }
    }, [successMessage])

    useEffect(() => {
        return () => {
            dispatch(resetBookADemo());
        }
    }, [])


    return (
        <>

            <Helmet>
                <title> Book a Demo with ProSchool.ai - See AI in Education</title>
                <meta name="description" content="  Experience the future of education. Book a demo to see how ProSchool.ai's AI-powered platform can transform learning."       />
                <meta name="keywords" content="Book demo, AI educational platform, ProSchool.ai demo, transformative learning, AI demo" />
                {/* Add more meta tags as needed */}
            </Helmet>

            <div className="book-demo-section">
                <h1 style={{display : 'none'}}> Book a Demo with ProSchool.ai </h1>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="book-demo-wrapper">
                                <div className="book-demo-ProschoolLogo" onClick={() => navigate(`/`)}>
                                    <img src={ProschoolLogo} alt="logo" />
                                </div>
                                <div className="book-demo-container">
                                    <h2>Book a Demo!</h2>
                                    <p>Join us for a 30 mins product walkthrough to see how ProSchool can elevate learning outcomes.</p>
                                    <form className="book-demo-form">
                                        <div className="book-demo-inner-input">
                                            <label>Full Name</label>
                                            <FormControl
                                                margin="none"
                                                fullWidth
                                                error={!!formErrors.full_name}
                                            >
                                                <TextField
                                                    name="full_name"
                                                    placeholder="E.g. Josh Buttler"
                                                    variant="outlined"
                                                    size="small"
                                                    value={formState.full_name}
                                                    error={!!formErrors.full_name}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        sx: {
                                                            borderRadius: "12px",
                                                            backgroundColor: "#F5F5F5",
                                                        },
                                                    }}
                                                />
                                                <FormHelperText>{formErrors.full_name}</FormHelperText>
                                            </FormControl>
                                        </div>
                                        <div className="book-demo-inner-input">
                                            <label>Email Address</label>
                                            <FormControl
                                                margin="none"
                                                fullWidth
                                                error={!!formErrors.email}
                                            >
                                                <TextField
                                                    name="email"
                                                    placeholder="E.g. josh@gmail.com"
                                                    variant="outlined"
                                                    size="small"
                                                    value={formState.email}
                                                    error={!!formErrors.email}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        sx: {
                                                            borderRadius: "12px",
                                                            backgroundColor: "#F5F5F5",
                                                        },
                                                    }}
                                                />
                                                <FormHelperText>{formErrors.email}</FormHelperText>
                                            </FormControl>
                                        </div>
                                        <div className="book-demo-inner-input">
                                            <label>Your phone number <span>(Optional)</span></label>

                                            <input
                                                type="number"
                                                placeholder="Mobile Number"
                                                id="teacher-create-profile-input"
                                                name="phone"
                                                style={
                                                    formErrors.phone
                                                        ? { border: "1px solid red" }
                                                        : null
                                                }
                                                value={formState.phone || ""}
                                                onChange={handleChange}
                                            />
                                            <div className="teacher-create-popup-gender-input-folder">
                                                <div className="form-icon">
                                                    <img src={call_image} alt="" />
                                                </div>
                                                {/* <select
                                                    className="teacher-create-popup-gender-input"
                                                    onChange={handleCountryChange}
                                                    value={formState.country_code}
                                                    name="country_code"
                                                >


                                                    {[{
                                                        "name": "",
                                                        "dial_code": "",
                                                        "code": ""
                                                    },].concat(Countries).map((country, index) => (
                                                        <option key={index}  value={country.dial_code} disabled={country.dial_code === "" ? true : false} selected={country.dial_code === "" ? true : false}>
                                                            {country.dial_code}
                                                        </option>
                                                    ))}
                                                </select> */}

                                                {/* <CountryCodeAutoComplete
                                                    value={formState.country_code}
                                                    onChange={handleCountryChange}
                                                    // onChange={(event, newValue) => {
                                                    //     // Update the form state with the selected country code
                                                    //     console.log(newValue.dial_code);
                                                    //     handleCountryChange({
                                                    //         target: {
                                                    //             name: "country_code",
                                                    //             value: newValue ? newValue.dial_code : "ns", // Assuming 'code' is the property containing the country code
                                                    //         },
                                                    //     });
                                                    // }}
                                                    id="country_code"
                                                    options={Countries}
                                                    getOptionLabel={(option) => option.dial_code } // Assuming 'name' is the property containing the country name
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            name="country_code"
                                                            placeholder="Enter Country"
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    )}
                                                /> */}




                                                <div className='select-phone-nymber'>
                                                    <CountryCodeAutoComplete
                                                        {...defaultProps}
                                                        id="clear-on-escape"
                                                        clearOnEscape
                                                        // filterOptions={filterOptions}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                name='country_code'
                                                                {...params}
                                                                variant="standard"
                                                                size="small"
                                                                onChange={(e) => setgetCountryCode(e.target.value)}
                                                            />
                                                        )}
                                                    />
                                                </div>

                                                {/* 
                                                <FormControl
                                                    error={!!formErrors.country_code}
                                                >
                                                    <CountryCodeAutoComplete
                                                        value={formState.country_code}
                                                        id="clear-on-escape"
                                                        clearOnEscape
                                                        options={filterOptions}
                                                        getOptionLabel={(option) => option || ""}
                                                        onChange={(event, newValue) => {
                                                            console.warn(newValue, 'hello    ')
                                                            handleCountryChange({
                                                                target: {
                                                                    name: "country_code",
                                                                    value: newValue ? newValue : "",
                                                                },
                                                            });
                                                        }}
                                                        inputValue={getCountryCode}
                                                        // onInputChange={(event, newInputValue, reason) => {
                                                        //     if (reason === "input") setgetCountryCode(newInputValue);
                                                        //     else if (reason === "clear") setgetCountryCode(""); 
                                                        // }}

                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                name="dial_code"
                                                                variant="outlined"
                                                                size="small"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                }}
                                                            />
                                                        )}
                                                    />

                                                </FormControl> */}


                                                {/* <CountryCodeAutoComplete
                                                    value={
                                                        formState.country_code
                                                    }
                                                    onChange={(event, newValue) => {
                                                        // Update the form state with the selected discount percentage
                                                        handleCountryChange({
                                                            target: {
                                                                name: "country_code",
                                                                value: newValue ? newValue : "", // Assuming newValue is the selected discount percentage
                                                            },
                                                        });
                                                    }}
                                                    id="country_code"
                                                    options={Countries}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            name="country_code"
                                                            placeholder="Eg. 10"
                                                            variant="outlined"
                                                            size="small"
                                                            //error={!!formErrors.discount_percentage}
                                                            //helperText={formErrors.discount_percentage}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                // value: params.InputProps.value ? capitalizeFirstLetter(params.InputProps.value) : "",
                                                                sx: {
                                                                    borderRadius: "12px",
                                                                    backgroundColor: "#F5F5F5",
                                                                    textTransform: "capitalize",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                    sx={{
                                                        "& .MuiAutocomplete-listbox": {
                                                            textTransform: "capitalize",
                                                        },
                                                    }}
                                                /> */}



                                            </div>
                                        </div>
                                        <div className="book-demo-inner-input">
                                            <label>Find Your School <span>(Optional)</span></label>
                                            <Autocomplete
                                                placeholder="E.g. Don Bosco School"
                                                value={formState.school_name}
                                                apiKey={GOOGLE_MAP_KEY}
                                                onPlaceSelected={handlePlaceSelect}
                                                defaultValue={formState.school_name}
                                                onChange={handleAutocompleteInputChange}
                                                onBlur={handleInputBlur}
                                                options={{
                                                    types: ['establishment'],
                                                }}
                                            />
                                            <i class="fa-solid fa-magnifying-glass"></i>
                                        </div>
                                    </form>
                                    <div className="What-brought-you-us-today-select">
                                        <h2>What brought you to us today?</h2>
                                        <div className="What-brought-you-us-today-select-folder">
                                            <div className="What-brought-you-us-today-select-input">
                                                <input
                                                    type="radio"
                                                    name="referred_by"
                                                    value="1"
                                                    onChange={handleChange}
                                                />
                                                <label>Social Media Platforms</label>
                                            </div>
                                            <div className="What-brought-you-us-today-select-input">
                                                <input
                                                    type="radio"
                                                    name="referred_by"
                                                    value="2"
                                                    onChange={handleChange}
                                                />
                                                <label>Word of Mouth</label>
                                            </div>
                                            <div className="What-brought-you-us-today-select-input">
                                                <input
                                                    type="radio"
                                                    name="referred_by"
                                                    value="3"
                                                    onChange={handleChange}
                                                />
                                                <label>Referrals</label>
                                            </div>
                                            <div className="What-brought-you-us-today-select-input">
                                                <input
                                                    type="radio"
                                                    name="referred_by"
                                                    value="4"
                                                    onChange={handleChange}
                                                />
                                                <label>Others</label>
                                            </div>
                                        </div>
                                        {showTextarea && (
                                            <textarea
                                                type="text"
                                                rows="3"
                                                cols="5"
                                                value={formState.answer || ""}
                                                onChange={handleChange}
                                                name="answer"
                                                placeholder="Type in your answer"
                                            />
                                        )}
                                    </div>
                                    <div className="request-demo-btn-folder">
                                        <div className="request-demo-btn">
                                            <button onClick={handleSubmit}>Request a Demo</button>
                                            <div className="request-demo-arrow-icon">
                                                <img src={request_demo_arrow_icon} />
                                            </div>
                                        </div>
                                        <span>Indicates Mandatory Fields</span>
                                    </div>
                                </div>
                                {successMessage && <div className='book-demon-request-received' style={{ right: successMessage ? "3%" : "-60%" }}>
                                    <div className='book-demon-request-received-image'>
                                        <img src={book_demon_request_received_image} />
                                    </div>
                                    <p>Your demo request has been received. Our team will contact you shortly.</p>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BookDemo;